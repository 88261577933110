import React from 'react';
import Foods from '../Assets/Images/FoodsWebsite.jpg';
import School from '../Assets/Images/SchoolDBMS.jpg';
import Todo from '../Assets/Images/TodoApp.jpg';
import Calculator from '../Assets/Images/Calculator.jpg';

const Portfolio = (props) => {
  return (
    <div className="portfolioContainer">
      <h1 className="title">Web Applications</h1>
      <div className="project">
        <img width="100%" height="225px" className="image" src={Foods} alt="Foods Website" />
        <a href="https://github.com/collinw1996/foodswebsite" target="_blank" rel="noopener noreferrer"><button className="button">Foods Website</button></a>
      </div>
      <div className="project">
        <img width="100%" height="225px" className="image" src={School} alt="School DBMS" />
        <a href="https://github.com/collinw1996/School-DBMS/tree/Collin" target="_blank" rel="noopener noreferrer"><button className="button">School DBMS</button></a>
      </div>
      <h1 className="title">Android Applications</h1>
      <div className="project" id="todo">
        <img width="100%" height="225px" className="image" src={Todo} alt="Todo App" />
        <a href="https://github.com/collinw1996/Todo-Android-App" target="_blank" rel="noopener noreferrer"><button className="button">Todo App</button></a>
      </div>
      <div className="project">
        <img width="100%" height="225px" className="image" src={Calculator} alt="Calculator" />
        <a href="https://github.com/collinw1996/Todo-Android-App" target="_blank" rel="noopener noreferrer"><button className="button">Calculator App</button></a>
      </div>
    </div>
  );
}

export default Portfolio;
