import React from 'react';

const Navigation = (props) => {
  return (
    <div className="navContainer">
      <button onClick={() => {props.view('about')}} className='navButton'>
        About
        </button>
      <button onClick={() => {props.view('resume')}} className='navButton'>
        Resume
        </button>
      <button onClick={() => {props.view('portfolio')}} className='navButton'>
        Portfolio
        </button>
    </div>
  );
}

export default Navigation;
