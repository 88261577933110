import React from 'react';
import Me from '../Assets/Images/Collin.jpg';

const About = (props) => {
  return (
    <div className="aboutContainer">
      <div className="about">
        <p>
          My name is Collin Woodruff and I possess a Bachelor of Science in Computer Science with a concentration in
          Software Engineering from Towson University. I graduated from Towson University in May of 2018.
            <br />
          <br />
          During my time at Towson University I took numerous computer science courses but throughout my college career
            I was drawn to the software engineering ones. Some of my favorite courses were:<br /></p>
        <ul>
          <li>Software Engineering</li>
          <li>Web Development</li>
          <li>Android Development</li>
          <li>Database Management</li>
        </ul>
        <p>
          With the skills I learned in these courses I am positive that I would be a great addition to any software engineering
          team. I am a motivated individual who enjoys solving problems and has good communication skills.
            <br />
          <br />
          I am currently a Lead Technical Analyst / Consultant for Tier 1 Consulting Group specializing in developing web applications using React,
          Sass, and Node.js. I also have a strong background in DevOps and DevSecOps.
            <br />
          <br />
          A little about me as a person is that I love to be around people and I’m easy to get along with.
          I enjoy playing soccer or any other sports with friends when the weather is nice or hanging out and socializing.
          I also enjoy playing video games during free time or working on a project while listening to music.
          I am a hardworking and passionate individual who is also competitive which contributes to my love for sports and video games.
          </p>
      </div>
      <img className="me" src={Me} alt="Collin Woodruff" />
    </div>
  );
}

export default About;