import React from 'react';
import MyApplication from '../Assets/Pdfs/Collin_Woodruff_Resume.pdf';
import Download from '../Assets/Images/Download.png';

const Resume = (props) => {
  return (
    <div className="resumeContainer">
      <div className="resume">
        <a href={MyApplication} target="_blank" rel="noopener noreferrer"><img className="button" src={Download} alt="Download" /></a> 
        <h1>Collin Woodruff</h1>
        <p>Frederick MD <br />
          240-566-6486 <br />
          collin.woodruff@yahoo.com </p>
        <hr />
        <h3>Interests</h3>
        <p>Software engineering, application development, mobile application development, and web development.</p>
        <h3>Education</h3>
        <p><strong>Towson University,</strong> Towson, MD</p>
        <ul>
          <li>Bachelor of Science: Computer Science with Software Engineering</li>
        </ul>
        <h3>Technical Skills</h3>
        <ul>
          <li><strong>Programming Languages:</strong> Java, C++, C, SQL, Ruby, PHP, JavaScript, jQuery, HTML and CSS</li>
          <li><strong>Applications:</strong> Microsoft Azure, Amazon Web Services, IDEs, ReactJs, Node.js, Express, Docker, Terraform, Ruby on Rails and Microsoft Office</li>
          <li><strong>Related Skills:</strong> DevOps, DevSecOps, Software engineering, object oriented design/object oriented programming, database administration, cloud development, web development, mobile development, testing and quality assurance.</li>
        </ul>
        <h3>Work Experience</h3>
        <p>Oct 2019 – Present<br />
          Lead Technical Analyst / Consultant for Tier 1 Consulting Group, Columbia, MD</p>
        <p>Mar 2019 – Oct 2019<br />
          Technical Analyst / Consultant for Tier 1 Consulting Group, Columbia, MD</p>
        <p>Jun 2018 – Mar 2019<br />
          Junior Programmer for Tier 1 Consulting Group, Columbia, MD</p>
        <p>Jan 2018 – Jun 2018<br />
          Programming Intern for Tier 1 Consulting Group, Columbia, MD</p>
        <p>Oct 2016 – Jan 2018<br />
          Sales Associate for Towson Bootery, Towson, MD</p>
        <p>May 2016 – Sept 2016<br />
          Lifeguard for American Pools, Towson, MD</p>
        <p>Jun 2014 – Aug 2015<br />
          Lifeguard for Serenity, Frederick, MD </p>
        <h3>Accomplishments</h3>
        <ul>
          <li>Dean’s List (Spring 2017, Fall 2016, Spring 2015)</li>
          <li>Founder of the Chi Phi fraternity at Towson University (Fall 2015)</li>
          <li>Intramural chair for Chi Phi fraternity at Towson University (Spring 2016 - Fall 2016)</li>
          <li>Graduated High School with Highest Honors (Spring 2014)</li>
        </ul>
        <h3>References</h3>
        <p>Available upon request</p>
      </div>
    </div>
  );
}

export default Resume;
