import React from 'react';
import Github from '../Assets/Images/Github.png';
import LinkedIn from '../Assets/Images/LinkedIn.png';

const Footer = (props) => {
  return (
    <div className="footerContainer">
      <div className="iconContainer">
        <div className="icon">
          <a href="https://www.linkedin.com/in/collin-woodruff" target="_blank" rel="noopener noreferrer"><img className="img" src={LinkedIn} alt="LinkedIn" /></a>
        </div>
        <div className="icon">
          <a href="https://github.com/collinw1996" target="_blank" rel="noopener noreferrer"><img className="img" src={Github} alt="Github" /></a>
        </div>
      </div>
      <hr />
      <p><em> &copy; Copyright {props.year} Collin Woodruff</em></p>
    </div>
  );
}

export default Footer;
