import React from 'react';
import Navigation from './components/Navigation.jsx';
import Footer from './components/Footer.jsx';
import Resume from './components/Resume.jsx';
import Portfolio from './components/Portfolio.jsx';
import About from './components/About.jsx';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      view: 'about',
      year: new Date().getFullYear()
    };
  }

  handleViewChange = (data) => {
    this.setState({view: data});
  }

  render() {
    return (
      <div id = "app">
        <div className="header">
          <Navigation view={this.handleViewChange}/>
        </div>
        <div className="body">
        { this.state.view === 'about' && 
          <About />
        }
        { this.state.view === 'resume' && 
          <Resume />
        }
        { this.state.view === 'portfolio' && 
          <Portfolio />
        }
        </div>
        <div className="footer">
          <Footer year={this.state.year} />
        </div>
      </div>
    );
  }
}

export default App;
